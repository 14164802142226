import React from 'react'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import { locale } from '../../../src/common/i18n'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingQuadPt = ({ styles, data }: Props) => {
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <article className={styles.description}>
        <p className={styles.main_description}>
        O Quad e os veículos ATV são concebidos para uma condução puramente Off-Road. Destina-se principalmente à utilização em competições desportivas de todos os tipos e percursos em terrenos arenosos e irregulares.
        </p>
        <section className={styles.section}>
          <h2>Como escolher os melhores pneus para o meu Quad?</h2>
          <p>
          O aspeto mais importante para escolher pneus de Quad é a dureza das suas borrachas. Num lugar com areia e zonas com pedras, a melhor opção é escolher pneus que utilizem um composto bastante duro. Para terrenos moles e húmidos, por outro lado, recomenda-se a utilização de pneus com uma borracha mole.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>Algumas sugestões para escolher pneus para o seu Quad</h2>
          <p>
          Existe uma série de recomendações que lhe aconselhamos a seguir para fazer uma boa escolha ao escolher pneus para o seu Quad:
          </p>
          <ul>
            <li>
            Escolha sempre pneus com a medida específica indicada pelo fabricante do veículo.
            </li>
            <li>
            Verifique se os índices de carga e velocidade estão de acordo com as especificações do seu Quad.
            </li>
            <li>
            Analise a utilização prevista do pneu e escolha borrachas mais ou menos duras em conformidade.
            </li>
            <li>
            Quando mudar de pneus, aconselhamo-lo também a realizar uma equilibragem. Tal como acontece com os automóveis, as rodas de Quad também requerem esta manutenção básica.
            </li>
            <li>
            Verifique regularmente as pressões das rodas. Este pequeno gesto ajudá-lo-á a prolongar a vida útil delas e permitirá que o seu Quad se adapte melhor às condições do terreno.
            </li>
          </ul>
        </section>
      </article>
    </div>
  )
}

const LandingQuad = ({ ...props }: Props) => <LandingQuadPt {...props} />

export default LandingQuad
